/** @jsx jsx **/
import { memo, ReactElement } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

export interface ContainerProps extends BoxProps {
  small?: boolean;
}

const Container = memo(function Container({
  small,
  ...boxProps
}: ContainerProps): ReactElement {
  return (
    <Box
      {...boxProps}
      sx={{
        width: "100%",
        maxWidth: small
          ? [null, "540px", "540px", "540px", "720px", "960px"]
          : [null, "540px", "720px", "960px", "1140px", "1320px"],
        paddingRight: ".75rem",
        paddingLeft: ".75rem",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    />
  );
});
export default Container;
