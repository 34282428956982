/** @jsx jsx */
import { Link } from "gatsby";
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import Container from "../components/Container";
import Trappings from "../components/Trappings";
import { PRICE_TEXT, REAL_PHARAOH_ADDRESS } from "../util/constants";
import { capitalize } from "../util/strings";

const NotFoundPage = memo(function NotFoundPage(): ReactElement {
  function renderEtherscanLink(network: string): ReactElement {
    return (
      <a
        href={`https://${network}.etherscan.io/address/${REAL_PHARAOH_ADDRESS}`}
      >
        {capitalize(network)}
      </a>
    );
  }

  return (
    <Trappings title="Pharaoh Token - About">
      <Container mt={5}>
        <p>
          Build your empire! Purchase a Pharaoh Token and recruit followers to
          earn money.
        </p>
        <p>
          Anyone with a Pharaoh Token can recruit followers. When someone
          decides to become your follower, they purchase their own Pharaoh Token
          for {PRICE_TEXT} and name you as their ancestor. Half of the{" "}
          {PRICE_TEXT} goes to you, and half is divided amongst your own
          ancestors.
        </p>
        <p>
          This means that if you can recruit just <b>two people</b>, then you
          have already earned the cost of the token. If you recruit a third
          person, or if either of your followers recruits a single person, then
          you have made a profit! Continue to build your empire for larger and
          larger gains.
        </p>
        <p>
          Pharaoh Tokens are NFTs and satisfy the ERC-721 standard. This means
          that you can transfer your Pharaoh token, passing all of the token's
          followers and any future gains to the new owner.
        </p>
        <p>Are you ready to build your empire?</p>
        <p sx={{ mt: 4 }}>
          GitHub:{" "}
          <a href="https://github.com/dphilipson/pharaoh-token">
            pharaoh-token
          </a>
          <br />
          Etherscan: {renderEtherscanLink("goerli")} /{" "}
          {renderEtherscanLink("kovan")} / {renderEtherscanLink("rinkeby")}
        </p>
        <p>Contract address (all networks): {REAL_PHARAOH_ADDRESS}</p>
        <p sx={{ mt: 4 }}>
          <Link to="/">Return to app</Link>
        </p>
        <p sx={{ mt: 5 }}>Copyright © 2021 David Philipson</p>
      </Container>
    </Trappings>
  );
});
export default NotFoundPage;
